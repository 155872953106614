import React, { ChangeEvent, useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Input,
  List,
  Select,
  Spinner,
  Title,
  Toggle,
  useToast,
} from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useTranslation } from 'react-i18next';
import {
  IStoreConfig,
  defaultStoreConfig,
} from '../../lib/interfaces/config.interfaces';
import apiInstance from '../../utils/apiUtils';
import { AppStatusBar } from '../../components';

const ConfigsPage: React.FC = () => {
  const { t } = useTranslation();

  const { addToast } = useToast();
  const [loadingGetConfig, setLoadingGetConfig] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [config, setConfig] = useState<IStoreConfig | null>(null);

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('general.back')}` });
    getConfig();
    setLoading(false);
  }, []);

  const handleChangeConfig = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    const helpConfig: any = { ...config };
    helpConfig[name] =
      type === 'checkbox' ? (event.target as HTMLInputElement).checked : value;
    setConfig({ ...helpConfig });
    return false;
  };

  const getConfig = async () => {
    try {
      const result = await apiInstance.get(`/store/config`);
      const tempConfig = { ...(result.data as IStoreConfig) };

      setConfig({ ...defaultStoreConfig, ...tempConfig });
    } catch (error) {
      setConfig({ ...defaultStoreConfig });
    }
    setLoadingGetConfig(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = { ...config };
      delete data._id;
      delete data.createdAt;
      delete data.updatedAt;
      delete data.store_id;
      const result = await apiInstance.post(`/store/config`, data);
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'success',
        text: `${t('ConfigsPage.submit_success')}`,
      });
      setLoading(false);
    } catch (error) {
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'danger',
        text: `${t('ConfigsPage.submit_danger')}`,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Page>
        <Page.Header
          title={t('ConfigsPage.title')}
          buttonStack={<AppStatusBar type="toggle" />}
        />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Box display="grid" gap="6">
                <Card>
                  <Card.Header
                    title={`${t('ConfigsPage.cards.general.title')}`}
                  />
                  <Card.Body>
                    <Box display="grid" gap="6">
                      <Checkbox
                        disabled={loadingGetConfig || loading}
                        name="is_stock_back"
                        label={`${t(
                          'ConfigsPage.cards.general.is_stock_back.label',
                        )}`}
                        checked={config?.is_stock_back || false}
                        onChange={handleChangeConfig}
                      />
                      <Checkbox
                        disabled={loadingGetConfig || loading}
                        name="is_notification"
                        label={`${t(
                          'ConfigsPage.cards.general.is_notification.label',
                        )}`}
                        checked={config?.is_notification || false}
                        onChange={handleChangeConfig}
                      />
                    </Box>
                  </Card.Body>
                </Card>

                <Box display="grid" gap="6" marginTop="4">
                  <Title as="h3">{t('ConfigsPage.cards.options_title')}</Title>
                  <Alert
                    appearance="warning"
                    title={`${t('ConfigsPage.cards.options_alert_title')}`}
                  >
                    <List as="ol">
                      <List.Item>
                        {t('ConfigsPage.cards.options_alert_text1')}
                      </List.Item>
                      <List.Item>
                        {t('ConfigsPage.cards.options_alert_text2')}
                      </List.Item>
                      <List.Item>
                        {t('ConfigsPage.cards.options_alert_text3')}
                      </List.Item>
                      <List.Item>
                        {t('ConfigsPage.cards.options_alert_text4')}
                      </List.Item>
                      <List.Item>
                        {t('ConfigsPage.cards.options_alert_text5')}
                      </List.Item>
                    </List>
                  </Alert>
                  <Card>
                    <Card.Header>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Title as="h3">
                          {t('ConfigsPage.cards.pix.title')}
                        </Title>
                        <Toggle
                          disabled={loading}
                          name="pix_active"
                          checked={config?.pix_active || false}
                          onChange={handleChangeConfig}
                          label={`${t(
                            'ConfigsPage.cards.pix.pix_active.label',
                          )}`}
                        />
                      </Box>
                    </Card.Header>
                    {config?.pix_active ? (
                      <Card.Body>
                        <Box display="grid" gap="6">
                          <FormField
                            label={`${t(
                              'ConfigsPage.cards.pix.pix_time.label',
                            )}`}
                          >
                            <Box display="flex" gap="4">
                              <Box maxWidth={'70px'}>
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="pix_time"
                                  name="pix_time"
                                  onChange={handleChangeConfig}
                                  value={config?.pix_time}
                                  type="number"
                                />
                              </Box>
                              <Box maxWidth={'100px'}>
                                <Select
                                  disabled={loadingGetConfig || loading}
                                  id="pix_time_type"
                                  name="pix_time_type"
                                  onChange={handleChangeConfig}
                                  value={config?.pix_time_type}
                                >
                                  <Select.Option
                                    value={'D'}
                                    label={t(
                                      'ConfigsPage.cards.default.time_type.option1',
                                    )}
                                  />
                                  <Select.Option
                                    value={'H'}
                                    label={t(
                                      'ConfigsPage.cards.default.time_type.option2',
                                    )}
                                  />
                                  <Select.Option
                                    value={'M'}
                                    label={t(
                                      'ConfigsPage.cards.default.time_type.option3',
                                    )}
                                  />
                                </Select>
                              </Box>
                            </Box>
                          </FormField>
                        </Box>
                      </Card.Body>
                    ) : (
                      <></>
                    )}
                  </Card>
                  <Card>
                    <Card.Header>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Title as="h3">
                          {t('ConfigsPage.cards.boleto.title')}
                        </Title>
                        <Toggle
                          disabled={loading}
                          name="boleto_active"
                          checked={config?.boleto_active || false}
                          onChange={handleChangeConfig}
                          label={`${t(
                            'ConfigsPage.cards.boleto.boleto_active.label',
                          )}`}
                        />
                      </Box>
                    </Card.Header>
                    {config?.boleto_active ? (
                      <Card.Body>
                        <Box display="grid" gap="6">
                          <FormField
                            label={`${t(
                              'ConfigsPage.cards.boleto.boleto_time.label',
                            )}`}
                          >
                            <Box display="flex" gap="4">
                              <Box maxWidth={'70px'}>
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="boleto_time"
                                  name="boleto_time"
                                  onChange={handleChangeConfig}
                                  value={config?.boleto_time}
                                  type="number"
                                />
                              </Box>
                              <Box maxWidth={'100px'}>
                                <Select
                                  disabled={loadingGetConfig || loading}
                                  id="boleto_time_type"
                                  name="boleto_time_type"
                                  onChange={handleChangeConfig}
                                  value={config?.boleto_time_type}
                                >
                                  <Select.Option
                                    value={'D'}
                                    label={t(
                                      'ConfigsPage.cards.default.time_type.option1',
                                    )}
                                  />
                                  {/* <Select.Option
                                    value={'H'}
                                    label={t(
                                      'ConfigsPage.cards.default.time_type.option2',
                                    )}
                                  />
                                  <Select.Option
                                    value={'M'}
                                    label={t(
                                      'ConfigsPage.cards.default.time_type.option3',
                                    )}
                                  /> */}
                                </Select>
                              </Box>
                            </Box>
                          </FormField>
                        </Box>
                      </Card.Body>
                    ) : (
                      <></>
                    )}
                  </Card>
                  <Card>
                    <Card.Header>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Title as="h3">
                          {t('ConfigsPage.cards.custom.title')}
                        </Title>
                        <Toggle
                          disabled={loading}
                          name="custom_active"
                          checked={config?.custom_active || false}
                          onChange={handleChangeConfig}
                          label={`${t(
                            'ConfigsPage.cards.custom.custom_active.label',
                          )}`}
                        />
                      </Box>
                    </Card.Header>
                    {config?.custom_active ? (
                      <Card.Body>
                        <Box display="grid" gap="6">
                          <FormField
                            label={`${t(
                              'ConfigsPage.cards.custom.custom_time.label',
                            )}`}
                          >
                            <Box display="flex" gap="4">
                              <Box maxWidth={'70px'}>
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="custom_time"
                                  name="custom_time"
                                  onChange={handleChangeConfig}
                                  value={config?.custom_time}
                                  type="number"
                                />
                              </Box>
                              <Box maxWidth={'100px'}>
                                <Select
                                  disabled={loadingGetConfig || loading}
                                  id="custom_time_type"
                                  name="custom_time_type"
                                  onChange={handleChangeConfig}
                                  value={config?.custom_time_type}
                                >
                                  <Select.Option
                                    value={'D'}
                                    label={t(
                                      'ConfigsPage.cards.default.time_type.option1',
                                    )}
                                  />
                                  <Select.Option
                                    value={'H'}
                                    label={t(
                                      'ConfigsPage.cards.default.time_type.option2',
                                    )}
                                  />
                                  <Select.Option
                                    value={'M'}
                                    label={t(
                                      'ConfigsPage.cards.default.time_type.option3',
                                    )}
                                  />
                                </Select>
                              </Box>
                            </Box>
                          </FormField>
                        </Box>
                      </Card.Body>
                    ) : (
                      <></>
                    )}
                  </Card>
                  {/* <Card>
                    <Card.Header>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Title as="h3">
                          {t('ConfigsPage.cards.card.title')}
                        </Title>
                        <Toggle
                          disabled={loading}
                          name="card_active"
                          checked={config?.card_active || false}
                          onChange={handleChangeConfig}
                          label={`${t(
                            'ConfigsPage.cards.card.card_active.label',
                          )}`}
                        />
                      </Box>
                    </Card.Header>
                    {config?.card_active ? (
                      <Card.Body>
                        <Box display="grid" gap="6">
                          <FormField
                            label={`${t(
                              'ConfigsPage.cards.card.card_time.label',
                            )}`}
                          >
                            <Box display="flex" gap="4">
                              <Box maxWidth={'70px'}>
                                <Input
                                  disabled={loadingGetConfig || loading}
                                  id="card_time"
                                  name="card_time"
                                  onChange={handleChangeConfig}
                                  value={config?.card_time}
                                  type="number"
                                />
                              </Box>

                              <Box maxWidth={'100px'}>
                                <Select
                                  disabled={loadingGetConfig || loading}
                                  id="card_time_type"
                                  name="card_time_type"
                                  onChange={handleChangeConfig}
                                  value={config?.card_time_type}
                                >
                                  <Select.Option
                                    value={'D'}
                                    label={t(
                                      'ConfigsPage.cards.default.time_type.option1',
                                    )}
                                  />
                                  <Select.Option
                                    value={'H'}
                                    label={t(
                                      'ConfigsPage.cards.default.time_type.option2',
                                    )}
                                  />
                                  <Select.Option
                                    value={'M'}
                                    label={t(
                                      'ConfigsPage.cards.default.time_type.option3',
                                    )}
                                  />
                                </Select>
                              </Box>
                            </Box>
                          </FormField>
                        </Box>
                      </Card.Body>
                    ) : (
                      <></>
                    )}
                  </Card> */}
                </Box>
                <Button
                  disabled={loading}
                  appearance="primary"
                  onClick={handleSubmit}
                >
                  {loading ? <Spinner color="currentColor" size="small" /> : ''}
                  {t('general.save')}
                </Button>
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
};

export default ConfigsPage;
