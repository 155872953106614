import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  IconButton,
  Pagination,
  Sidebar,
  Table,
  Text,
  useToast,
} from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { IUserLog } from '../../lib/interfaces/userLog.interfaces';
import { IPaginateMeta } from '../../lib/interfaces/paginate.interfaces';
import apiInstance from '../../utils/apiUtils';
import { formatDate } from '../../utils/formatUtils';
import { DisketteIcon } from '@nimbus-ds/icons';

function LogUsersList() {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [userLogs, setUserLogs] = useState<IUserLog[]>([]);
  const [groupsMeta, setGroupsMeta] = useState<IPaginateMeta>();
  const [searchByAction, setSearchByAction] = useState<string>('');
  const [haveUserLog, setHaveUserLog] = useState(false);
  const [detailConfig, setDetailConfig] = useState<{
    [key: string]: any;
  }>({});

  const toggleOpen = (config: string | null = null) => {
    if (config !== null) {
      setDetailConfig(JSON.parse(config));
    }
    setOpenModal((prevState) => !prevState);
  };

  const loadLogs = async (page = groupsMeta?.page || 1) => {
    setLoading(true);
    try {
      const params: string[] = [];

      if (searchByAction) {
        params.push(`action=${searchByAction}`);
      }

      const result = await apiInstance.get(
        `/user-logs?sort=-createdAt&page=${page}${
          params.length ? `&${params.join('&')}` : ``
        }`,
      );
      if (result.data !== null) {
        setUserLogs(result.data.result as IUserLog[]);
        setGroupsMeta(result.data.meta as IPaginateMeta);

        if (!haveUserLog && userLogs.length) {
          setHaveUserLog(true);
        }
      }
    } catch (error) {
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: t('MainPage.loading.error'),
      });
    }
    setLoading(false);
  };

  const printValue = (value: string | number | boolean): string => {
    if (typeof value === 'boolean') {
      return value ? 'Sim' : 'Não';
    }
    return `${value}`;
  };

  useEffect(() => {
    loadLogs();
  }, [searchByAction]);

  return (
    <>
      <Box display="grid" gap="10">
        <Table>
          <Table.Head>
            <Table.Cell as="th">
              {t('MainPage.LogUsersList.Table.Head.Cell1')}
            </Table.Cell>
            <Table.Cell as="th">
              {t('MainPage.LogUsersList.Table.Head.Cell2')}
            </Table.Cell>
            <Table.Cell as="th">
              {t('MainPage.LogUsersList.Table.Head.Cell3')}
            </Table.Cell>
            <Table.Cell as="th" width="70px">
              {''}
            </Table.Cell>
          </Table.Head>
          <Table.Body>
            {loading ? (
              <Table.Row>
                <Table.Cell>
                  <Text.Skeleton />
                </Table.Cell>
                <Table.Cell>
                  <Text.Skeleton />
                </Table.Cell>
                <Table.Cell>
                  <Text.Skeleton />
                </Table.Cell>
                <Table.Cell>
                  <IconButton.Skeleton />
                </Table.Cell>
              </Table.Row>
            ) : userLogs.length ? (
              userLogs.map((log, index) => (
                <Table.Row key={index}>
                  <Table.Cell>#{log.user_id}</Table.Cell>
                  <Table.Cell>{log.action}</Table.Cell>
                  <Table.Cell>{formatDate(log.createdAt || '')}</Table.Cell>
                  <Table.Cell>
                    <IconButton
                      onClick={() => toggleOpen(log.config_json)}
                      size="30px"
                      source={<DisketteIcon size="small" />}
                    />
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell>
                  {t('MainPage.LogUsersList.Table.Body.CellEmpty')}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        {groupsMeta ? (
          <Pagination
            activePage={groupsMeta.page}
            onPageChange={loadLogs}
            pageCount={groupsMeta.pages}
          />
        ) : (
          <></>
        )}
      </Box>
      <Sidebar padding="base" open={openModal} onRemove={toggleOpen}>
        <Sidebar.Header title="Detalhes" />
        <Box boxSizing="border-box" display="grid" padding="2" gap="2">
          {Object.entries(detailConfig).map(([key, value]) =>
            !['_id', 'store_id'].includes(key) ? (
              <Text key={key}>
                <Box as="b">{key}</Box>: {printValue(value)}
              </Text>
            ) : (
              <></>
            ),
          )}
        </Box>
      </Sidebar>
    </>
  );
}

export default LogUsersList;
