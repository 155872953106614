export interface IStoreConfig {
  _id?: string;
  store_id: number;
  boleto_active: boolean;
  boleto_time_type: 'H' | 'M' | 'D';
  boleto_time: number;
  custom_active: boolean;
  custom_time_type: 'H' | 'M' | 'D';
  custom_time: number;
  pix_active: boolean;
  pix_time_type: 'H' | 'M' | 'D';
  pix_time: number;
  card_active: boolean;
  card_time_type: 'H' | 'M' | 'D';
  card_time: number;
  is_notification: boolean;
  is_stock_back: boolean;
  is_active: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export const defaultStoreConfig: IStoreConfig = {
  store_id: 0,
  boleto_active: false,
  boleto_time_type: 'D',
  boleto_time: 5,
  custom_active: false,
  custom_time_type: 'D',
  custom_time: 5,
  pix_active: false,
  pix_time_type: 'H',
  pix_time: 5,
  card_active: false,
  card_time_type: 'H',
  card_time: 1440,
  is_notification: true,
  is_stock_back: true,
  is_active: true,
};
