import { useEffect, useState } from 'react';

import {
  Box,
  Icon,
  Pagination,
  Tag,
  Text,
  useToast,
} from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { IPaginateMeta } from '../../lib/interfaces/paginate.interfaces';
import apiInstance from '../../utils/apiUtils';
import { formatDate } from '../../utils/formatUtils';
import { CreditCardIcon, PesoIcon } from '@nimbus-ds/icons';
import { ICustomOrder } from '../../lib/interfaces/customOrder.interfaces';
import { DataList } from '@nimbus-ds/patterns';

function CustomOrdersList() {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [customOrders, setCustomOrders] = useState<ICustomOrder[]>([]);
  const [meta, setMeta] = useState<IPaginateMeta>();
  const [searchByOrder, setSearchByOrder] = useState<string>('');
  const [haveOrder, setHaveOrder] = useState(false);

  const loadData = async (page = meta?.page || 1) => {
    setLoading(true);
    try {
      const params: string[] = [];

      if (searchByOrder) {
        params.push(`ns_order_id=${searchByOrder}`);
      }

      const result = await apiInstance.get(
        `/custom-order?sort=-app_cancelled_at&page=${page}${
          params.length ? `&${params.join('&')}` : ``
        }`,
      );
      if (result.data !== null) {
        setCustomOrders(result.data.result as ICustomOrder[]);
        setMeta(result.data.meta as IPaginateMeta);

        if (!haveOrder && setCustomOrders.length) {
          setHaveOrder(true);
        }
      }
    } catch (error) {
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: t('MainPage.loading.error'),
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [searchByOrder]);

  return (
    <>
      <Box display="grid" gap="10">
        <DataList>
          {loading ? (
            <DataList.Row gap="1">
              <Box display="flex" justifyContent="space-between">
                <Text.Skeleton />
                <Text.Skeleton />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Box display="grid" gap="2">
                  <Text.Skeleton />
                  <Text.Skeleton />
                </Box>
                <Text.Skeleton />
              </Box>
              <Box display="flex" flexWrap="wrap" gap="2" pt="2">
                <Tag.Skeleton>
                  <Icon.Skeleton />
                  <Text.Skeleton />
                </Tag.Skeleton>
                <Tag.Skeleton>
                  <Icon.Skeleton />
                  <Text.Skeleton />
                </Tag.Skeleton>
              </Box>
            </DataList.Row>
          ) : customOrders.length ? (
            customOrders.map((order, i) => (
              <DataList.Row key={i} gap="1">
                <Box display="flex" justifyContent="space-between">
                  <Text fontWeight="medium" color="primary-interactive">
                    #{order.ns_order_id}
                  </Text>
                  <Text>{formatDate(order.app_cancelled_at || '')}</Text>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box display="grid" gap="2">
                    <Text>{order.contact_name}</Text>
                    <Text>{order.contact_email}</Text>
                  </Box>
                  <Text>{order.contact_phone}</Text>
                </Box>
                <Box display="flex" flexWrap="wrap" gap="2" pt="2">
                  <Tag appearance="warning">
                    <Icon color="currentColor" source={<CreditCardIcon />} />
                    {order.payment_method}
                  </Tag>
                  <Tag appearance="primary">
                    <Icon color="currentColor" source={<PesoIcon />} />
                    {order.payment_status}
                  </Tag>
                </Box>
              </DataList.Row>
            ))
          ) : (
            <></>
          )}
        </DataList>
        {meta ? (
          <Pagination
            activePage={meta.page}
            onPageChange={loadData}
            pageCount={meta.pages}
          />
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

export default CustomOrdersList;
