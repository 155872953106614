import { useEffect } from 'react';

import { Box, Icon, Link, Tabs } from '@nimbus-ds/components';
import { HelpLink, Layout, Page } from '@nimbus-ds/patterns';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';

import nexo from '../../nexoClient';
import { useTranslation } from 'react-i18next';
import { AppStatusBar, ConfigButton } from '../../components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import LogUsersList from './LogUsersList';
import CustomOrdersList from './CustomOrdersList';

function PageMain() {
  const { t } = useTranslation();

  useEffect(() => {
    navigateHeaderRemove(nexo);
  }, []);

  return (
    <>
      <Page>
        <Page.Header
          title={t('MainPage.title')}
          buttonStack={
            <Box display="flex" gap="2">
              <ConfigButton />
            </Box>
          }
        />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <AppStatusBar />
            </Layout.Section>
            <Layout.Section>
              <Box display="grid" gap="10">
                <Tabs fullWidth>
                  <Tabs.Item label={`${t('MainPage.Tabs.Tab1.label')}`}>
                    <CustomOrdersList />
                  </Tabs.Item>
                  <Tabs.Item label={`${t('MainPage.Tabs.Tab2.label')}`}>
                    <LogUsersList />
                  </Tabs.Item>
                </Tabs>
              </Box>
            </Layout.Section>
            <Layout.Section>
              <HelpLink>
                <Link
                  as="a"
                  href="https://huapps.com.br/ajuda/cancelamento-automatico/pt"
                  target="_blank"
                  appearance="primary"
                  textDecoration="none"
                >
                  {t('MainPage.helpText')}
                  <Icon source={<ExternalLinkIcon />} color="currentColor" />
                </Link>
              </HelpLink>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
}

export default PageMain;
